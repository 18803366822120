<template>
  <div class="dashboard-container">
    <Operate
      v-if="dialog.refresh"
      :dialog.sync="dialog.show"
      :row="dialog.row"
      :info="dialog.info"
      @get-list="getList"
      @refresh="refreshDialog"
    />
    <el-row :gutter="20">
      <el-col>
        <el-card>
          <el-row type="flex" justify="space-between" :gutter="50">
            <el-col  :span="5">
              <el-input v-model="condition.search" class="input searchInput" size="mini" suffix-icon="el-icon-search" placeholder="搜索" @input="searchInput" />
            </el-col>
          </el-row>
        </el-card>
        <el-card style="margin-top: 20px;">
          <el-row>
            <vxe-toolbar>
              <template v-slot:buttons>
                <el-row type="flex" justify="between">
                  <el-col>
                    <el-button v-if="$hasBtn('sys:permissions:config:type:add')" type="primary" size="mini" @click="openDialog({title:'添加一级类型',addOr:'addRoot'})">添加一级类型</el-button>
                    <el-button v-if="$hasBtn('sys:permissions:config:type:allDelete')" type="danger" size="mini" @click="remove({del:'batch'})">批量删除</el-button>
                    <el-button type="warning" size="mini" @click="$refs.table.setAllTreeExpand(true)">展开所有</el-button>
                    <el-button type="info" size="mini" @click="$refs.table.clearTreeExpand()">收起所有</el-button>
                  </el-col>
                  <el-col :span="1">
                    <vxe-button @click="getList">刷新</vxe-button>
                  </el-col>
                </el-row>
              </template>
            </vxe-toolbar>
            <vxe-table
              ref="table"
              border
              resizable
              show-overflow
              :auto-resize="true"
              :header-row-style="headerStyle"
              :row-style="rowStyle"
              align="center"
              class="vxe-table-element"
              height="600"
              row-id="id"
              :tree-config="{children: 'children', expandAll: true,indent:15}"
              :data="tableList"
              :loading="loading"
            >
              <vxe-table-column type="checkbox" width="60" fixed="left" />
              <vxe-table-column type="seq" width="60" />
              <vxe-table-column
                field="typeName"
                title="类型名称"
                align="left"
                tree-node
              />
              <vxe-table-column field="" title="操作">
                <template v-slot="{ row, rowIndex }">
                  <el-dropdown size="small" type="primary">
                    <el-button size="small" type="primary">
                      操作<i class="el-icon-arrow-down el-icon--right" />
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item v-if="$hasBtn('sys:permissions:config:type:add')" icon="el-icon-plus" @click.native="openDialog({title:'添加下级类型',addOr:'addSon'},row)">添加</el-dropdown-item>
                      <el-dropdown-item v-if="$hasBtn('sys:permissions:config:type:edit')" icon="el-icon-edit" @click.native="openDialog({title:'修改',addOr:'upd'},row)">修改</el-dropdown-item>
                      <el-dropdown-item v-if="$hasBtn('sys:permissions:config:type:delete')" icon="el-icon-delete" @click.native="remove({del:'single'},row)">删除</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
              </vxe-table-column>
            </vxe-table>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { rowStyle, headerStyle } from '@/utils/tableStyleJs'
import { getCourseType, courseType, courseTypeBatch } from '@/api'
import Operate from './Operate'
import XEUtils from 'xe-utils'
export default {
  name: 'Index',
  components: {
    Operate
  },
  data() {
    return {
      loading: false,
      condition: {
        search: ''
      },
      table: {
        list: [],
        total: 0,
        currentPage: 1,
        pageSize: 10,
        sortName: '',
        sortBy: ''
      },
      dialog: {
        row: Object,
        info: {},
        refresh: false, // 每次刷新DOM
        show: false
      }
    }
  },
  computed: {
    tableList(v) {
      const filterName = XEUtils.toString(this.condition.search).trim().toLowerCase()
      if (filterName) {
        const filterRE = new RegExp(filterName, 'gi')
        const searchProps = ['typeName']
        const rest = this.table.list.filter(item => searchProps.some(key => XEUtils.toString(item[key]).toLowerCase().indexOf(filterName) > -1))
        return rest.map(row => {
          const item = Object.assign({}, row)
          searchProps.forEach(key => {
            item[key] = XEUtils.toString(item[key]).replace(filterRE, match => `${match}`)
          })
          return item
        })
      }
      return this.table.list
    }
  },
  created() {
    this.getList()
  },
  methods: {
    rowStyle,
    headerStyle,
    // 打开dialog
    openDialog(info, row) {
      this.dialog.refresh = true
      this.dialog.show = true
      this.dialog.info = info
      this.dialog.row = row
    },
    searchInput(v) {
      this.getList()
    },
    getList() {
      this.loading = true
      this.$axiosReq(getCourseType, null, null, 'get').then(res => {
        this.table.list = res.data
        // this.$refs.table.loadData(res.data)
        this.loading = false
      }).catch(e => {
        this.loading = false
      })
    },
    remove(info, row) {
      const id = []
      if (info.del === 'batch') {
        const arr = this.$refs.table.getCheckboxRecords()
        const arr1 = this.$refs.table.getCheckboxReserveRecords()
        const batchDel = arr.concat(arr1)
        for (const i of batchDel) {
          id.push(i.id)
        }
        if (id.length === 0) {
          this.$message.error('请勾选！！！')
          return
        }
        this.$confirm('确认删除吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$axiosReq(courseTypeBatch, null, {
            ids: id.join()
          }, 'delete').then(res => {
            this.$message.success(res.msg)
            this.$refs.table.clearCheckboxRow()
            this.$refs.table.clearCheckboxReserve()
            this.getList()
          })
        })
      } else {
        this.$confirm('确认删除吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$axiosReq(courseType + row.id, null, null, 'delete').then(res => {
            this.$message.success(res.msg)
            this.$refs.table.clearCheckboxRow()
            this.$refs.table.clearCheckboxReserve()
            this.getList()
          })
        })
      }
    },
    handleSizeChange(pageSize) {
      this.table.pageSize = pageSize
      this.table.currentPage = 1
      this.getList()
    },
    handleCurrentChange(currentPage) {
      this.table.currentPage = currentPage
      this.getList()
    },
    refreshDialog() {
      this.dialog.refresh = false
    }
  }
}
</script>

<style scoped>

</style>
